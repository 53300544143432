import Vue from "vue";
import Router from "vue-router";
import Home from "./Home.vue";
import Transmission from "./Transmission.vue";
import Cypher from "./Cypher.vue";
import Retailers from "./Retailers.vue";

Vue.use(Router);

export default new Router({
	mode: "history",
	routes: [
		{
			path: "/",
			name: "home",
			component: Home
		},
		{
			path: "/retailers",
			name: "retailers",
			component: Retailers
		},
		{
			path: "/strangetransmission",
			name: "Transmission",
			component: Transmission
		},
		{
			path: "/cypher",
			name: "Cypher",
			component: Cypher
		},
		{
			path: '*',
			component: Home
		}
	]
});
