<template>
	<div id="main-content">
		<div class="row justify-content-center">
			<div class="col-12 col-md-6 text-center mt-5">
				<img src="~@/assets/BHPLogo.png" class="img-fluid main-logo" />
			</div>
		</div>
		<div class="row justify-content-center">
			<div class="col-12 col-md-3">
				<a target="_blank" href="https://twitter.com/Bleak_Press" aria-label="Twitter"><img src="~@/assets/TwitterButton.png" class="img-fluid" alt="Twitter" /></a>
			</div>
			<div class="col-12 col-md-3">
				<a target="_blank" href="https://bleak-horizons-press.itch.io/" aria-label="Itch.io"><img src="~@/assets/ItchButton.png" class="img-fluid" alt="Itch.io" /></a>
			</div>
		</div>
		<div class="row justify-content-center">
			<div class="col-12 col-md-3">
				<a target="_blank" href="https://www.drivethrurpg.com/browse/pub/19722/Bleak-Horizons-Press" aria-label="DriveThruRPG.com"><img src="~@/assets/DTRPGButton.png" class="img-fluid" alt="DriveThruRPG.com" /></a>
			</div>
			<div class="col-12 col-md-3">
				<router-link to="/retailers"><img src="~@/assets/RetailerButton.png" class="img-fluid" alt="Retailers" /></router-link>
			</div>
		</div>
		<div class="row justify-content-center">
			<div class="col-12 col-md-6 text-justify">
				<p>Bleak Horizons Press publishes tabletop RPG material - from stories set in existing game-worlds to its own signature setting, ALL MUST BOW - with a dash of fiction and poetry. Works on our imprint are reflections on the stark conditions of reality, attempts at catharsis. We do not wallow. We do not leer. Nor do we look away. Behold with us, and weep.</p>
			</div>
		</div>
	</div>
</template>
<script>

export default {
	name: 'Home',
}
</script>