<template>
	<div id="transmission">
		<div class="row">
			<div class="col">
				<audio 
					controls
					loop
					src="https://docs.google.com/uc?export=download&id=1HBiPe1TL63OmETbuCtnZATUbIu_PvpvC">					
					Your browser does not support the <code>audio</code> element.
				</audio>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'Transmission',
}
</script>
<style lang="scss">
</style>