<template>
	<div id="retailers">
		<div class="row justify-content-center">
			<div class="col-12 col-md-6 text-center mt-5">
				<router-link to="/"><img src="~@/assets/BHPLogoTree.png" class="img-fluid main-logo" /></router-link>
			</div>
		</div>
		<div class="row justify-content-center">
			<div class="col-12 col-md-6">
				<h2>Check out our fantastic retailers for physical copies of Bleak Horizons Press products:</h2>

				<h3 class="my-4">United States</h3>
				<ul role="list">
					<li><a href="https://www.ironbuffalogaming.com" target="_blank">Iron Buffalo (in-person) Buffalo, NY</a></li>
					<li><a href="https://floatingchair.club" target="_blank">Floating Chair Club (online)</a></li>
					<li><a href="https://spearwitch.com" target="_blank">Spear Witch (online)</a></li>
					<li><a href="https://twentysidedstore.com" target="_blank">Twenty Sided Store (in-person, online) Brooklyn, NY</a></li>
					<li><a href="https://www.vaultofmidnight.com" target="_blank">Vault of Midnight (in-person) Ann Arbor, MI</a></li>
					<li><a href="https://www.gatherandgame.com/" target="_blank">Gather &amp; Game (in-person, online) Buffalo, NY</a></li>
					<li><a href="https://www.facebook.com/heartsofthegame/" target="_blank">Heart of the Game (in-person) Buffalo, NY</a></li>
				</ul>

				<h3 class="my-4">Canada</h3>
				<ul role="list">
					<li><a href="https://rattiincantati.com" target="_blank">Ratti Incantati (online)</a></li>
					<li><a href="https://monkeyspawgames.com" target="_blank">Monkey's Paw Games (online)</a></li> 
				</ul> 

				<h3 class="my-4">United Kingdom</h3>
				<ul role="list">
					<li><a href="https://iglootree.com" target="_blank">Iglootree (in-person, online) London, UK</a></li>
					<li><a href="https://www.rookspress.com" target="_blank">Rook's Press (online)</a></li>
				</ul>
			</div>
		</div>
		<div class="row justify-content-center d-none d-lg-flex">
			<div class="col-12 col-md-6">
				<router-link to="/">Go back...</router-link>
			</div>
		</div>
		<div class="row d-lg-none d-flex">
			<div class="col-6">
				<router-link to="/">Go back...</router-link>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'Retailers',
}
</script>
<style lang="scss">
#retailers {
	ul {
		list-style-type: '- ';
	}
}
</style>