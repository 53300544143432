<template>
	<div id="app">
		<div class="container">
			<router-view :email="email"></router-view>
		</div>
	</div>
</template>

<script>

export default {
	name: 'App',
	components: {
	}
}
</script>

<style lang="scss">
	// Google Fonts
	@import url(https://fonts.googleapis.com/css2?family=Lora);

	// Font Variables
	$lora: 'Lora', serif;

	html,
	body {
		height: 100% !important;
	}

	body {
		background-color: black !important;
	}

	#app {
		font-family: $lora;
		color: white;
		.main-logo {
			min-width: 100%;
		}
	}

	@media (min-width: 600px) {
		#app {
			background-image: url('~@/assets/BackgroundTileLarge.jpg');
			background-color: transparent;
		}
	}

</style>
